import { useTranslation } from "react-i18next";
import { useUserState } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./header.styles.module.scss";
import React from "react";

const Header = () => {
  const { t } = useTranslation();
  const { firstName } = useUserState();
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <TypographyComponent color="gartnerBlue" boldness="semi" type="p3">
          {t("Welcome")} {firstName}!
        </TypographyComponent>
      </div>
    </section>
  );
};

export default Header;
