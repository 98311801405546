import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { Fragment, KeyboardEvent, MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HomeAppContext } from "../../../app.context";
import { navigateToUrl } from "single-spa";
import LastViewedMarketsIcon from "./last-viewed-markets.icon";
import styles from "./last-viewed-markets.styles.module.scss";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";

const LastViewedMarketsComponent = () => {
  const { t } = useTranslation();
  const { recentTemplateViews } = useContext(HomeAppContext);
  const captureEvents = useCaptureEventsV2();
  const toMarketProfileUrl = (templateId: string) => `/s/market/${templateId}`;
  const handleMarketClick = (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
    templateId: string
  ) => {
    e.preventDefault();
    captureEvents([
      {
        eventType: ClientEvent.HOME_RECENT_MARKET_CLICKED,
        metaData: {
          templateId: templateId,
        },
      },
    ]);
    navigateToUrl(toMarketProfileUrl(templateId));
  };

  return (
    <Fragment>
      <TypographyComponent color="iron" boldness="medium" type="p5" rootClassName={styles.sectionTitle}>
        {t("Last viewed markets")}
      </TypographyComponent>
      <ol aria-label={t("market list")} className={styles.listContainer}>
        {recentTemplateViews.map((templateView) => (
          <li key={templateView.id} className={styles.marketListItem}>
            <TooltipV2
              title={templateView.name}
              placement="top"
              enterDelay={1500}
              enterNextDelay={1500}
              PopperProps={{
                modifiers: { offset: { offset: "0, 10px" } },
              }}
              rootClassName={styles.textToolTip}
            >
              <a
                onKeyDown={(e) => e.key === "Enter" && handleMarketClick(e, templateView.id)}
                onClick={(e) => handleMarketClick(e, templateView.id)}
                href={toMarketProfileUrl(templateView.id)}
                className={styles.marketLink}
              >
                <div className={styles.listIcon}>
                  <LastViewedMarketsIcon />
                </div>
                <TypographyComponent color="coal" boldness="semi" type="p4" rootClassName={styles.marketNameText}>
                  {templateView.name}
                </TypographyComponent>
              </a>
            </TooltipV2>
          </li>
        ))}
      </ol>
    </Fragment>
  );
};

export default LastViewedMarketsComponent;
