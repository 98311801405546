import { KeyboardEvent, MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { HomeAppContext } from "../../app.context";
import ResearchHistoryIcon from "./research-history.icon";
import NoResearchHistory from "./no-research-history/no-research-history.component";
import LastViewedMarketsComponent from "./last-viewed-markets/last-viewed-markets.component";
import LastViewedVendorsComponent from "./last-viewed-vendors/last-viewed-vendors.component";
import styles from "./research-history.styles.module.scss";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent } from "../../app.constants";

const ResearchHistoryComponent = () => {
  const { t } = useTranslation();
  const { recentTemplateViews, recentProductViews } = useContext(HomeAppContext);
  const captureEvents = useCaptureEventsV2();
  const handleBrowseMarketsClick = (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    captureEvents([
      {
        eventType: ClientEvent.HOME_BROWSE_MARKETS_CLICKED,
      },
    ]);
    navigateToUrl("/s/markets");
  };

  return (
    <section aria-label={t("My research history")} className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <ResearchHistoryIcon />
        </div>
        <TypographyComponent color="yam" boldness="semi" type="p3">
          {t("My research history")}
        </TypographyComponent>
      </div>
      <div className={styles.body}>
        <div className={styles.container}>
          {recentTemplateViews.length === 0 && recentProductViews.length === 0 && <NoResearchHistory />}
          {recentTemplateViews.length > 0 && <LastViewedMarketsComponent />}
          {recentProductViews.length > 0 && <LastViewedVendorsComponent />}
        </div>
        <div className={styles.browse}>
          <TextLink text={t("Browse markets")} onClick={handleBrowseMarketsClick} />
        </div>
      </div>
    </section>
  );
};

export default ResearchHistoryComponent;
