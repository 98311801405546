import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  ProductLogo,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import { InitStatus, timeFromDateFormat } from "gx-npm-lib";
import { isEqual } from "date-fns";
import { StarV2Icon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";

import { Product } from "../../../../app.types";
import styles from "./eval-card-footer.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type EvalCardFooterProps = {
  products: Product[];
  status: string;
  lastActivity: {
    accessDate: string;
    firstName: string;
    lastName: string;
  };
};

const maxProductDisplay = 3;

const EvalCardFooter = ({ products, status, lastActivity }: EvalCardFooterProps) => {
  const { t } = useTranslation();
  const { firstName, lastName, accessDate } = lastActivity;

  const additionalProductCount = products.length - maxProductDisplay;

  const defaultDate = new Date("1970-01-01T00:00:00.000Z");
  const lastActivityDate = accessDate ? new Date(accessDate) : defaultDate;
  const showLastActivity = !isEqual(lastActivityDate, defaultDate);

  return (
    <div className={styles.container}>
      <div className={styles.products}>
        {products.length === 0 && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={styles.noProductsGCOM3606} color={"iron"} styling={"p4"}>
                {t("No vendors under consideration")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent rootClassName={styles.noProducts} type="p4">
                {t("No vendors under consideration")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        {products.length > 0 && status === InitStatus.SELECTED && (
          <div className={styles.awardedProduct}>
            <ProductLogo
              logo={products[0].imageLoc}
              imageWidth={"32px"}
              imageHeight={"32px"}
              svgStyle={{ width: "28.8px", height: "28.5px" }}
            />
            <StarV2Icon rootClassName={styles.awardedIcon} fillPath={colorPalette.status.lemon.hex} />
            <TypographyComponent type="p3" boldness="semi">
              {t("Selected vendor")}
            </TypographyComponent>
          </div>
        )}
        {products.length > 0 && status !== InitStatus.SELECTED && (
          <div className={styles.productList}>
            {products.slice(0, maxProductDisplay).map((product) => (
              <div className={styles.productLogo} key={product.productName}>
                <ProductLogo
                  logo={product.imageLoc}
                  imageWidth={"32px"}
                  imageHeight={"32px"}
                  svgStyle={{ width: "28.8px", height: "28.5px" }}
                />
              </div>
            ))}
            <TypographyComponent rootClassName={styles.moreProducts} type="p4">
              {additionalProductCount > 0 ? `+${additionalProductCount} more` : ""}
            </TypographyComponent>
          </div>
        )}
      </div>
      {showLastActivity && (
        <div className={styles.lastActivity}>
          <UsernameDisplay isSmallerIcon={true} name={`${firstName} ${lastName}`} showName={false} />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={styles.lastActivityTime} styling="p4">
                {t("Last activity")} {timeFromDateFormat(accessDate)}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent rootClassName={styles.lastActivityTime} type="p4">
                {t("Last activity")} {timeFromDateFormat(accessDate)}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      )}
    </div>
  );
};

export default EvalCardFooter;
